import Mock from "../mock";

const updateAge = () => {
  const DOB = 751521600000;
  const MILLIS_IN_A_YEAR = 31536000000;
  const MONTHS_IN_A_YEAR = 12;
  const DAYS_IN_A_MONTH = 30;
  const HOURS_IN_A_DAY = 24;
  const MINUTES_IN_A_HOUR = 60;
  const SECONDS_IN_A_MINUTE = 60;
  const age = (Date.now() - DOB) / MILLIS_IN_A_YEAR;
  const ageYear = Math.floor(age);
  const months = (age - ageYear) * MONTHS_IN_A_YEAR;
  const ageMonth = Math.floor(months);
  const days = (months - ageMonth) * DAYS_IN_A_MONTH;
  const ageDay = Math.floor(days);
  const hours = (days - ageDay) * HOURS_IN_A_DAY;
  const ageHour = String(Math.floor(hours)).padStart(2, "0");
  const minutes = (hours - ageHour) * MINUTES_IN_A_HOUR;
  const ageMinute = String(Math.floor(minutes)).padStart(2, "0");
  const seconds = (minutes - ageMinute) * SECONDS_IN_A_MINUTE;
  const ageSecond = String(Math.floor(seconds)).padStart(2, "0");

  return {
    age: ageYear,
    ageYear,
    ageMonth,
    ageDay,
    ageHour,
    ageMinute,
    ageSecond
  }
}

const database = {
  information: {
    name: 'Hugens Ulysse',
    aboutContent: "I am a Senior Software Engineer. I pride myself on delivering well-architected software solutions that focus on code longevity and robustness.",
    ...updateAge(),
    phone: '',
    nationality: 'American',
    language: 'English',
    email: '',
    address: 'West Palm Beach, FL',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/hugens-ulysse-153668159/',
      dribbble: '',
      github: 'https://github.com/Hugens25'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.png',
    aboutImageLg: '/images/about-image-lg.png',
    cvfile: '/files/Hugens_Ulysse_Resume.pdf'
  },
  services: [
    {
      title: "Solution Design",
      icon: 'brush-alt',
      details: "I design comprehensive solutions tailored to optimizing performance and scalability, ensuring seamless integration and long-term success."
    },
    {
      title: "Software Development",
      icon: 'code',
      details: "I develop high-quality software systems that meet exact client needs, leveraging my expertise in agile methodologies for efficient and effective project delivery."
    },
    {
      title: "Cloud Architecture",
      icon: 'cloud',
      details: "I architect cutting-edge cloud environments that maximize scalability, security, and efficiency, tailored to meet the unique needs of modern businesses."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "Python",
      value: 95
    },
    {
      title: "Java",
      value: 80
    },
    {
      title: "Javascript/React/HTML/CSS",
      value: 75
    },
    {
      title: "AWS",
      value: 90
    },
    {
      title: "SQL/NoSQL Databases",
      value: 80
    },
    {
      title: "Terraform/Infrastructure as Code",
      value: 95
    },
    {
      title: "CI/CD",
      value: 95
    },
    {
      title: "Docker/Kubernetes",
      value: 75
    },
    {
      title: "REST/GraphQL/gRPC",
      value: 70
    },
    {
      title: "Kafka/Message Queues",
      value: 90
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2021 - Present",
        position: "Tech Lead Software Developer",
        company: "Southwest Airlines",
        details: "Worked on a modernization effort to move a mission-critical business capability to the Cloud. We are projected to save the company $100 million annually and will improve the company's operational performance while simultaneously enhancing customer experience."
      },
      {
        id: 2,
        year: "2021 - 2021",
        position: "Cloud Engineer",
        company: "Amazon Web Services",
        details: "Collaborated with various AWS customers to mitigate cloud integration issues related to the serverless tech-stack. I had exposure to a wide range of company architectures from small startup companies to Fortune 500 Enterprises."
      },
      {
        id: 3,
        year: "2019 - 2021",
        position: "Software Engineer",
        company: "State Farm",
        details: "Worked on a modernization effort to reduce State Farm's time-to-market. The successful implementation led to modularized deployments, allowing internal teams to leverage reusable components to streamline the customer rating process."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2014 - 2019",
        graduation: "Bachelor of Science",
        university: "University of Central Florida",
        details: "Computer Science"
      },
    ]
  },
  contactInfo: {
    phoneNumbers: ['+1 (469) 215 - 1038',],
    emailAddress: ['dev@hugens-ulysse.com',],
    address: "West Palm Beach, FL"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = {...database.information, ...updateAge()};
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});